<template>
  <v-container fluid class="pa-4">
    <v-row>
      <v-col>
        <v-text-field label="Consumo" v-model="potencias.consumo" dense hide-details filled />
      </v-col>
      <v-col>
        <v-text-field label="Fee Energia" v-model="potencias.feeEnergia" dense hide-details filled />
      </v-col>
      <v-col>
        <v-select
          :items="['2.0TD', '3.0TD', '6.1TD', '3.0A']"
          v-model="potencias.tarifa"
          dense
          hide-details
          filled
          label="Tarifa"
        ></v-select>
      </v-col>
    </v-row>
    <v-subheader>Potencias</v-subheader>
    <v-row class="mb-4">
      <v-col v-if="preciosBOE.P1 > 0" cols="2">
        <v-text-field label="P1" v-model="potencias.p1" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P2 > 0" cols="2">
        <v-text-field label="P2" v-model="potencias.p2" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P3 > 0" cols="2">
        <v-text-field label="P3" v-model="potencias.p3" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P4 > 0" cols="2">
        <v-text-field label="P4" v-model="potencias.p4" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P5 > 0" cols="2">
        <v-text-field label="P5" v-model="potencias.p5" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P6 > 0" cols="2">
        <v-text-field label="P6" v-model="potencias.p6" dense hide-details filled />
      </v-col>
    </v-row>

    <v-subheader>FEEs Potencia</v-subheader>
    <v-row class="mb-4">
      <v-col v-if="preciosBOE.P1 > 0" cols="2">
        <v-text-field label="P1" v-model="potencias.feep1" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P2 > 0" cols="2">
        <v-text-field label="P2" v-model="potencias.feep2" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P3 > 0" cols="2">
        <v-text-field label="P3" v-model="potencias.feep3" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P4 > 0" cols="2">
        <v-text-field label="P4" v-model="potencias.feep4" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P5 > 0" cols="2">
        <v-text-field label="P5" v-model="potencias.feep5" dense hide-details filled />
      </v-col>
      <v-col v-if="preciosBOE.P6 > 0" cols="2">
        <v-text-field label="P6" v-model="potencias.feep6" dense hide-details filled />
      </v-col>
    </v-row>

    <v-subheader>ATR potencia</v-subheader>
    <v-simple-table>
      <thead>
        <tr>
          <th>Periodo</th>
          <th>Precio</th>
          <th>Precio / 365</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="preciosBOE.P1 > 0">
          <td>P1</td>
          <td>{{ atr.p1 }}</td>
          <td>{{ parseFloat(atr.p1 / 365).toFixed(8) }}</td>
        </tr>
        <tr v-if="preciosBOE.P2 > 0">
          <td>P2</td>
          <td>{{ atr.p2 }}</td>
          <td>{{ parseFloat(atr.p2 / 365).toFixed(8) }}</td>
        </tr>
        <tr v-if="preciosBOE.P3 > 0">
          <td>P3</td>
          <td>{{ atr.p3 }}</td>
          <td>{{ parseFloat(atr.p3 / 365).toFixed(8) }}</td>
        </tr>
        <tr v-if="preciosBOE.P4 > 0">
          <td>P4</td>
          <td>{{ atr.p4 }}</td>
          <td>{{ parseFloat(atr.p4 / 365).toFixed(8) }}</td>
        </tr>
        <tr v-if="preciosBOE.P5 > 0">
          <td>P5</td>
          <td>{{ atr.p5 }}</td>
          <td>{{ parseFloat(atr.p5 / 365).toFixed(8) }}</td>
        </tr>
        <tr v-if="preciosBOE.P6 > 0">
          <td>P6</td>
          <td>{{ atr.p6 }}</td>
          <td>{{ parseFloat(atr.p6 / 365).toFixed(8) }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="mt-4 d-flex align-center" style="width: 100%;">
      <h4>COMISION:</h4>
      <v-chip label outlined color="secondary" class="v-chip--active ml-2">{{ comision | eur }}</v-chip>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-chip
        label
        outlined
        color="primary"
        class="v-chip--active ml-2"
      >{{ ComisionContrato | eur }}</v-chip>
    </div>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-subheader>{{ contrato.Canal }}</v-subheader>
      <v-subheader>{{ contrato.SubAgente }}</v-subheader>
    </div>
  </v-container>
</template>

<script>


export default {
  data() {
    return {
      potencias: {
        feeEnergia: 0,
        consumo: 0,
        tarifa: null,
        p1: null, feep1: null,
        p2: null, feep2: null,
        p3: null, feep3: null,
        p4: null, feep4: null,
        p5: null, feep5: null,
        p6: null, feep6: null,
      },
      TarifaGrupo: null,
      preciosBOE: {},
      codigoContrato: this.$route.query.CodigoContrato || this.CodigoContrato || null,
      contrato: {}
    }
  },
  props: ['CodigoContrato', 'ComisionContrato'],
  computed: {
    total() {
      return this.selected.reduce((t, a) => t + a, 0)
    },
    atr() {
      return {
        p1: parseFloat(Number(this.preciosBOE.P1) + Number(this.potencias.feep1)).toFixed(6) * 1,
        p2: parseFloat(Number(this.preciosBOE.P2) + Number(this.potencias.feep2)).toFixed(6) * 1,
        p3: parseFloat(Number(this.preciosBOE.P3) + Number(this.potencias.feep3)).toFixed(6) * 1,
        p4: parseFloat(Number(this.preciosBOE.P4) + Number(this.potencias.feep4)).toFixed(6) * 1,
        p5: parseFloat(Number(this.preciosBOE.P5) + Number(this.potencias.feep5)).toFixed(6) * 1,
        p6: parseFloat(Number(this.preciosBOE.P6) + Number(this.potencias.feep6)).toFixed(6) * 1,
      }
    },
    comision() {
      const { p1, p2, p3, p4, p5, p6 } = this.potencias;
      const { feep1, feep2, feep3, feep4, feep5, feep6, } = this.potencias;

      return parseFloat(this.potencias.feeEnergia * (this.potencias.consumo / 1000) * 0.7) + (((feep1 * p1) + (feep2 * p2) + (feep3 * p3) + (feep4 * p4) + (feep5 * p5) + (feep6 * p6)) * 0.7).toFixed(2) * 1
    }
  },
  async mounted() {
    if (!this.codigoContrato) return;

    let str = this.$store.getters.getDatosEmpresa;
    const { IdAgente } = str.iss;

    const { data } = await axios({
      method: 'GET',
      url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
      params: {
        token: this.$store.getters.getJwtEmpresa,
        codigoContrato: this.codigoContrato,
        IdAgente
      }
    })

    this.contrato = data

    if (!data.TarifaGrupo.includes('FLEXIBLE')) {
      this.$root.$emit('snack', 'No es un producto flexible')
      // throw new Error('No es flexible')
      // this.$router.go(-1)
      return;
    }

    this.TarifaGrupo = data.TarifaGrupo

    const { data: datosFlexible } = await axios({
      method: 'GET',
      url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
      params: {
        token: this.$store.getters.getJwtEmpresa,
        productoFlexible: data.TarifaGrupo
      }
    })

    this.potencias.p1 = data.P1 * 1;
    this.potencias.p2 = data.P2 * 1;
    this.potencias.p3 = data.P3 * 1;
    this.potencias.p4 = data.P4 * 1;
    this.potencias.p5 = data.P5 * 1;
    this.potencias.p6 = data.P6 * 1;

    this.potencias.feeEnergia = datosFlexible.ImporteEnergia * 1;
    this.potencias.feep1 = Math.round(datosFlexible.feep1 * 2) / 2;
    this.potencias.feep2 = Math.round(datosFlexible.feep2 * 2) / 2;
    this.potencias.feep3 = Math.round(datosFlexible.feep3 * 2) / 2;
    this.potencias.feep4 = Math.round(datosFlexible.feep4 * 2) / 2;
    this.potencias.feep5 = Math.round(datosFlexible.feep5 * 2) / 2;
    this.potencias.feep6 = Math.round(datosFlexible.feep6 * 2) / 2;

    this.potencias.consumo = data.ConsumoEstimado * 1

    this.potencias.tarifa = data.Tarifa.replace(' ML', '')
  },
  watch: {
    "potencias.tarifa": {
      handler(tarifa) {
        if (this.codigoContrato) {
          axios({
            method: 'GET',
            url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
            params: {
              token: this.$store.getters.getJwtEmpresa,
              productoFlexible: this.TarifaGrupo
            }
          }).then(({ data: datosFlexible }) => {
            this.preciosBOE = {
              P1: datosFlexible.P1 * 1,
              P2: datosFlexible.P2 * 1,
              P3: datosFlexible.P3 * 1,
              P4: datosFlexible.P4 * 1,
              P5: datosFlexible.P5 * 1,
              P6: datosFlexible.P6 * 1,
            }
          })
        } else {
          axios({
            method: "GET",
            url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
            params: {
              token: this.$store.getters.getJwtEmpresa,
              tarifa,
            },
          }).then(({ data }) => {
            this.preciosBOE = data
          });
        }
      },
      immediate: false
    }
  }
}
</script>